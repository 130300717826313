export const sections = {
  preferences: {
    label: 'Preferences',
    icon: 'preferences',
    info: 'Waitlist, customer access & booking limits',
    path: '/minisite/preferences',
    tags: ['waitlist', 'customer access', 'booking limits']
  },
  theme: {
    label: 'Theme',
    icon: 'preferences',
    info: 'Colours, images & logos',
    path: '/minisite/theme',
    tags: ['colours', 'images', 'logos']
  },
  widget: {
    label: 'Widgets',
    icon: 'widget',
    info: 'Embed code for widgets and ovatu bot',
    path: '/minisite/embed',
    tags: ['embed', 'widget', 'ovatu bot']
  },
  booking: {
    label: 'Booking',
    icon: 'calendar',
    info: 'Online booking, timetable and last minute bookings',
    path: '/minisite/booking',
    match: '/minisite/booking',
    tags: ['page','tab','booking', 'timetable', 'last', 'minute', 'schedule', 'calendar', 'online', 'online booking']
  },
  giftCards: {
    label: 'Gift Cards',
    icon: 'giftCard',
    info: 'Sell gift cards online',
    path: '/minisite/gift-cards',
    tags: ['page','tab','gift cards', 'giftcards', 'gift', 'cards']
  },
  passes: {
    label: 'Passes',
    icon: 'pass',
    info: 'Sell passes online',
    path: '/minisite/passes',
    tags: ['page','tab','passes', 'pass']
  },
  reviews: {
    label: 'Reviews',
    icon: 'review',
    info: 'Reviews from your customers',
    path: '/minisite/reviews',
    tags: ['page','tab','reviews', 'review']
  },
  customer: {
    label: 'Customer (Account)',
    icon: 'customer',
    info: 'Customer account page',
    path: '/minisite/customer',
    tags: ['page','tab','customer', 'account', 'login', 'manage']
  },
  siteNotice: {
    label: 'Site Notice',
    icon: 'warning',
    info: 'Include an alert at the top of your book.app site',
    path: '/minisite/site-notice',
    tags: ['alert', 'information', 'site notice', 'notice']
  },
  gallery: {
    label: 'Gallery',
    icon: 'gallery',
    info: 'Show a gallery of images on your book.app site',
    path: '/minisite/gallery',
    tags: ['gallery']
  },
  contact: {
    label: 'Contact',
    icon: 'product',
    info: 'Contact details and address',
    path: '/minisite/contact',
    tags: ['contact', 'details', 'address']
  },
  businessHours: {
    label: 'Business Hours',
    icon: 'businessHours',
    info: 'Business hours and holidays',
    path: '/minisite/hours',
    tags: ['business hours', 'hours', 'holidays']
  },
  social: {
    label: 'Social',
    icon: 'integrations',
    info: 'Social accounts',
    path: '/minisite/social',
    tags: ['social', 'accounts', 'facebook', 'instagram', 'twitter', 'linkedin', 'pinterest', 'youtube', 'tiktok', 'snapchat', 'whatsapp', 'telegram' ]
  },
  payments: {
    label: 'Payments',
    icon: 'payment',
    info: 'Manage online payment and deposit options',
    path: '/minisite/payments',
    tags: ['online payment', 'deposit options', 'pay', 'pay in full', 'deposit', 'pay later']
  },
  newCustomers: {
    label: 'New customers',
    icon: 'customFields',
    info: 'What fields are required for new customer registrations',
    path: '/minisite/new-customers',
    tags: ['fields', 'new customer', 'registrations']
  },
  minimiseGaps: {
    label: 'Minimise Gaps',
    icon: 'forms',
    info: 'Reduce unwanted gaps and manage times between appointments created online. ',
    path: '/minisite/minimise-gaps',
    tags: ['gaps', 'times', 'appointments']
  },
  terms: {
    label: 'Terms',
    icon: 'giftCard',
    info: 'Online booking customer terms and conditions',
    path: '/minisite/terms',
    tags: ['terms', 'conditions'],
  },
  domain: {
    label: 'Domain',
    icon: 'menuOnlineBooking',
    info: 'Set a custom domain for your minisite',
    path: '/minisite/domain',
    tags: ['domain', 'custom domain', 'custom url', 'url']
  },
  script: {
    label: 'Custom Script',
    icon: 'schedule',
    info: 'Custom script for your minisite',
    path: '/minisite/script',
    tags: ['javascript', 'css', 'google', 'conversion', 'code']
  },
  seo: {
    label: 'SEO',
    icon: 'search',
    info: 'Search engine optimisation settings',
    path: '/minisite/seo',
    tags: ['seo', 'noindex', 'nofollow', 'meta', 'tags']
  }
}

export const pages = [
  {
    label: 'Preferences',
    icon: 'preferences',
    info: 'Waitlist, customer access & booking limits',
    path: '/minisite/preferences',
    tabs: {
      preferences: sections.preferences
    }
  },
  {
    label: 'Theme',
    icon: 'preferences',
    info: 'Colours, images & logos',
    path: '/minisite/theme',
    tabs: {
      theme: sections.theme
    }
  },
  {
    label: 'Widgets',
    icon: 'widget',
    info: 'Embed code for widgets and ovatu bot',
    path: '/minisite/embed',
    tabs: {
      widget: sections.widget
    }
  },
  {
    label: 'Pages',
    icon: 'services',
    info: 'Enable pages on your book.app site',
    path: '/minisite/booking',
    match: '/minisite/booking',
    tabs: {
      booking: sections.booking,
      giftCards: sections.giftCards,
      passes: sections.passes,
      reviews: sections.reviews,
      customer: sections.customer,
      siteNotice: sections.siteNotice,
      gallery: sections.gallery,
    }
  },
  {
    label: 'Custom pages',
    icon: 'giftCard',
    info: 'Create additional pages for your book.app site',
    match: '/minisite/custom-pages',
    tabs: [
      {
        label: 'Custom Pages',
        icon: 'giftCard',
        info: 'Create additional pages for your book.app site',
        path: '/minisite/custom-pages',
        tags: ['custom', 'pages']
      }
    ]
  },
  {
    label: 'Menu',
    icon: 'minisiteMenu',
    info: 'Set the order of items in the menu',
    match: '/minisite/menu',
    tabs: [
      {
        label: 'Menu',
        icon: 'minisiteMenu',
        info: 'Set the order of items in the menu',
        path: '/minisite/menu',
        tags: ['menu', 'order']
      }
    ]
  },
  {
    label: 'Business info',
    icon: 'product',
    info: 'Contact details, social accounts and business hours',
    path: '/minisite/business-info/contact',
    match: '/minisite/business-info',
    tabs: {
      contact: sections.contact,
      businessHours: sections.businessHours,
      social: sections.social,
    }
  },
  {
    label: 'Payments',
    icon: 'payment',
    info: 'Manage online payment and deposit options',
    path: '/minisite/payments',
    tags: ['online payment', 'deposit options'],
    tabs: {
      payments: sections.payments
    }
  },
  {
    label: 'New customers',
    icon: 'customFields',
    info: 'What fields are required for new customer registrations',
    path: '/minisite/new-customers',
    tabs: {
      newCustomers: sections.newCustomers
    }
  },
  {
    label: 'Minimise Gaps',
    icon: 'forms',
    info: 'Reduce unwanted gaps and manage times between appointments created online. ',
    path: '/minisite/minimise-gaps',
    tabs: {
      minimiseGaps: sections.minimiseGaps
    }
  },
  {
    label: 'Terms',
    icon: 'giftCard',
    info: 'Online booking customer terms and conditions',
    path: '/minisite/terms',
    tabs: {
      terms: sections.terms
    }
  },
  {
    label: 'Advanced',
    icon: 'warning',
    info: 'Advanced minisite options',
    path: '/minisite/domain',
    match: '/minisite/domain',
    tabs: {
      domain: sections.domain,
      script: sections.script,
      seo: sections.seo,
    }
  }
]
